<template>
  <div class="tasks-page">
    <PageHeader>
      <h1 class="heading">
        Tasks
      </h1>
    </PageHeader>

    <div style="font-size: 48px;max-width: 520px" class="text-center mx-auto mt-16 py-16">
      Tasks page <br> is under construction
    </div>


<!--    <v-container>-->
<!--      <div class="common-page-filters d-flex align-center mx-auto">-->
<!--        <ByDate :value="filters.created_at" @onChange="(value) => setFilter({created_at: value})" style="max-width: 220px"/>-->
<!--        <div class="d-flex align-center ml-auto">-->
<!--          <UiSearch class="common-page-filters-search" inputClass="rounded mr-1"-->
<!--                    @onSearch="(value) => setFilter({search: value})"></UiSearch>-->
<!--          <Filters v-if="getProjectsList.length"></Filters>-->
<!--          <v-btn-->
<!--            v-if="filters.project_id && getPermission(filters.project_id)['project-s-drawer']['can-create-task-p-s-d']"-->
<!--            :disabled="!filters.project_style_id || filters.project_style_id === null"-->
<!--            class="text-uppercase ml-2"-->
<!--            color="primary"-->
<!--            fab-->
<!--            height="32"-->
<!--            style="box-shadow: 0 4px 4px rgb(0, 0, 0, .05)"-->
<!--            width="32"-->
<!--            @click="activeTask = null; openTaskForm()"-->
<!--          >-->
<!--            <v-icon class="white&#45;&#45;text" size="14">fa-plus</v-icon>-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div ref="tasks_body" class="project-side-drawer-tasks pb-8" @scroll="loadMoreTasks">-->

<!--        <div v-if="!tasks.data.length && !loading" class="pa-4 text-center font-weight-medium text_color_23&#45;&#45;text">-->
<!--          No tasks available. Please create them or set another filter options.-->
<!--        </div>-->

<!--        <v-data-table-->
<!--          v-show="tasks.data.length"-->
<!--          :custom-sort="customSort"-->
<!--          :headers="headers"-->
<!--          :items="tasks.data"-->
<!--          :items-per-page="-1"-->
<!--          class="tasks-table rounded-0"-->
<!--          hide-default-footer-->
<!--          mobile-breakpoint="280"-->
<!--          must-sort-->
<!--          sort-by="name"-->
<!--        >-->
<!--          <template v-slot:item="{ item }">-->
<!--            <tr :class="{'is-active' : activeTask && item.id === activeTask.id}">-->
<!--              <td>-->
<!--                <v-btn-->
<!--                  :disabled="item.status === $config.tasks.status.done || !editEnable(item)"-->
<!--                  class="tasks-table-complete-task-btn"-->
<!--                  fab-->
<!--                  height="32"-->
<!--                  icon-->
<!--                  width="32"-->
<!--                  @click="item.status = $config.tasks.status.done; updateTask(item)"-->
<!--                >-->
<!--                  <v-icon v-if="item.status !== $config.tasks.status.done" class="text_color_26&#45;&#45;text" size="18">far-->
<!--                    fa-square-->
<!--                  </v-icon>-->
<!--                  <v-icon v-else class="primary&#45;&#45;text done-icon" size="18">fa-check-square</v-icon>-->
<!--                </v-btn>-->
<!--              </td>-->
<!--              <td-->
<!--                :class="['text_color_26&#45;&#45;text overflow-ellipsis overflow-hidden text-no-wrap', {'text-decoration-line-through' : item.status === $config.tasks.status.done}]"-->
<!--                :style="{maxWidth: '240px', cursor: editEnable(item) ? 'pointer' : 'auto'}"-->
<!--                @click="updateTaskFormField(item, 'name')"-->
<!--              >-->
<!--                {{ item.name }}-->
<!--              </td>-->
<!--              <td>-->
<!--                <v-avatar-->
<!--                  v-if="item.project_item"-->
<!--                  color="background_21"-->
<!--                  rounded-->
<!--                  size="24"-->
<!--                  style="cursor: pointer"-->
<!--                  @click="go(item)"-->
<!--                >-->
<!--                  <img :src="item.project_item.file.url" alt="">-->
<!--                </v-avatar>-->
<!--              </td>-->
<!--              <td>-->
<!--                <UIImage-->
<!--                  height="18"-->
<!--                  name-path="icons/ic-folder-tree-grey.svg"-->
<!--                  style="vertical-align: sub; cursor: pointer"-->
<!--                  width="18"-->
<!--                  @click="openTaskForm(item)"-->
<!--                ></UIImage>-->
<!--              </td>-->
<!--              <td>-->
<!--                <UIDatePicker-->
<!--                  v-model="item.due_date"-->
<!--                  :disabled="!editEnable(item)"-->
<!--                  :disabled-date="disabledBeforeToday"-->
<!--                  @save="(data) => {item.due_date = data.value; updateTask(item)}"-->
<!--                >-->
<!--                  <template v-slot:input>-->
<!--                    <div :style="{cursor: editEnable(item) ? 'pointer' : 'auto'}"-->
<!--                         class="d-flex align-center text_color_26&#45;&#45;text">-->
<!--                      <v-icon class="text_color_26&#45;&#45;text mr-2" size="16">far fa-clock</v-icon>-->
<!--                      {{ formatDueDate(item.due_date) }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </UIDatePicker>-->
<!--              </td>-->
<!--              <td>-->
<!--                <v-select-->
<!--                  v-model="item.priority"-->
<!--                  :disabled="!editEnable(item)"-->
<!--                  :items="priorities"-->
<!--                  :menu-props="{contentClass: 'task-table-select-input-menu', offsetY: true, left: true, minWidth: 210}"-->
<!--                  append-icon=""-->
<!--                  class="task-table-select-input"-->
<!--                  dense-->
<!--                  hide-details-->
<!--                  persistent-hint-->
<!--                  @change="updateTask(item)"-->
<!--                >-->
<!--                  <template v-slot:selection="{ item }">-->
<!--                    <UIImage :name-path="`icons/ic-${$config.tasks.priorityLabels[item].toLowerCase()}-priority.svg`"-->
<!--                             height="16"-->
<!--                             width="16"></UIImage>-->
<!--                  </template>-->
<!--                  <template v-slot:item="{ item }">-->
<!--                    <div class="d-flex align-center text_color_19&#45;&#45;text font-weight-medium">-->
<!--                      <UIImage :name-path="`icons/ic-${$config.tasks.priorityLabels[item].toLowerCase()}-priority.svg`"-->
<!--                               class="mr-4" height="14"-->
<!--                               width="14"></UIImage>-->
<!--                      {{ $config.tasks.priorityLabels[item] }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--              </td>-->
<!--              <td>-->
<!--                <v-select-->
<!--                  v-model="item.status"-->
<!--                  :disabled="!editEnable(item)"-->
<!--                  :items="statuses"-->
<!--                  :menu-props="{contentClass: 'task-table-select-input-menu', offsetY: true, left: true, minWidth: 210}"-->
<!--                  append-icon=""-->
<!--                  class="task-table-select-input"-->
<!--                  dense-->
<!--                  hide-details-->
<!--                  persistent-hint-->
<!--                  @change="updateTask(item)"-->
<!--                >-->
<!--                  <template v-slot:selection="{ item }">-->
<!--                    <v-icon :class="`tasks-status-${item}`" size="15">fa-circle</v-icon>-->
<!--                  </template>-->
<!--                  <template v-slot:item="{ item }">-->
<!--                    <div class="d-flex align-center text_color_19&#45;&#45;text font-weight-medium">-->
<!--                      <v-icon :class="`tasks-status-${item} mr-4`" size="14">fa-circle</v-icon>-->
<!--                      {{ $config.tasks.statusLabels[item] }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="d-flex align-center">-->
<!--                  <UISelectAssignee-->
<!--                    v-model="item.assignee"-->
<!--                    :assignee-list="getProject.team"-->
<!--                    :disabled="!editEnable(item)"-->
<!--                    :menuWidth="260"-->
<!--                    @input="updateTask(item)"-->
<!--                  >-->
<!--                    <template v-slot:input>-->
<!--                      <v-tooltip color="accent" top>-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                          <div class="d-flex align-center mr-3" v-bind="attrs" v-on="on">-->
<!--                            <v-avatar size="24">-->
<!--                              <img v-if="item.assignee.avatar" :src="item.assignee.avatar.url" alt="">-->
<!--                              <v-icon v-else class="text_color_26&#45;&#45;text" size="16">fa-user</v-icon>-->
<!--                            </v-avatar>-->
<!--                          </div>-->
<!--                        </template>-->
<!--                        <span class="white&#45;&#45;text font-weight-medium">{{-->
<!--                            item.assignee.first_name-->
<!--                          }} {{ item.assignee.last_name }}</span>-->
<!--                      </v-tooltip>-->
<!--                    </template>-->
<!--                  </UISelectAssignee>-->
<!--                  <v-icon class="text_color_26&#45;&#45;text" size="18" @click="showMenu($event, item)">fa-ellipsis-v</v-icon>-->
<!--                </div>-->
<!--                <div v-if="item.loading" class="task-table-tr-loading d-flex align-center justify-center background_2">-->
<!--                  <v-progress-circular :size="32" color="primary" indeterminate/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->

<!--            <tr-->
<!--              v-for="subTask in item.children"-->
<!--              :key="subTask.id"-->
<!--              :class="{'is-active' : activeTask && subTask.id === activeTask.id}"-->
<!--            >-->
<!--              <td>-->
<!--                <div class="d-flex align-center">-->
<!--                  <v-btn-->
<!--                    :disabled="subTask.status === $config.tasks.status.done || !editEnable(subTask)"-->
<!--                    class="tasks-table-complete-task-btn"-->
<!--                    fab-->
<!--                    height="32"-->
<!--                    icon-->
<!--                    width="32"-->
<!--                    @click="subTask.status = $config.tasks.status.done; updateTask(subTask)"-->
<!--                  >-->
<!--                    <v-icon v-if="subTask.status !== $config.tasks.status.done" class="text_color_26&#45;&#45;text" size="18">far-->
<!--                      fa-square-->
<!--                    </v-icon>-->
<!--                    <v-icon v-else class="primary&#45;&#45;text done-icon" size="18">far fa-check-square</v-icon>-->
<!--                  </v-btn>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td-->
<!--                :class="['text_color_26&#45;&#45;text overflow-ellipsis overflow-hidden text-no-wrap', {'text-decoration-line-through' : subTask.status === $config.tasks.status.done}]"-->
<!--                :style="{maxWidth: '240px', cursor: editEnable(subTask) ? 'pointer' : 'auto'}"-->
<!--                @click="updateTaskFormField(subTask, 'name')"-->
<!--              >-->
<!--                {{ subTask.name }}-->
<!--              </td>-->
<!--              <td></td>-->
<!--              <td>-->
<!--                <UIImage-->
<!--                  height="18"-->
<!--                  name-path="icons/ic-folder-tree.svg"-->
<!--                  style="vertical-align: sub; cursor: pointer"-->
<!--                  width="18"-->
<!--                  @click="openTaskForm(subTask)"-->
<!--                ></UIImage>-->
<!--              </td>-->
<!--              <td>-->
<!--                <UIDatePicker-->
<!--                  v-model="subTask.due_date"-->
<!--                  :disabled="!editEnable(subTask)"-->
<!--                  :disabled-date="disabledBeforeToday"-->
<!--                  @save="(data) => {subTask.due_date = data.value; updateTask(subTask)}"-->
<!--                >-->
<!--                  <template v-slot:input>-->
<!--                    <div :style="{cursor: editEnable(item) ? 'pointer' : 'auto'}"-->
<!--                         class="d-flex align-center text_color_26&#45;&#45;text">-->
<!--                      <v-icon class="text_color_26&#45;&#45;text mr-2" size="16">far fa-clock</v-icon>-->
<!--                      {{ formatDueDate(subTask.due_date) }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </UIDatePicker>-->
<!--              </td>-->
<!--              <td>-->
<!--                <v-select-->
<!--                  v-model="subTask.priority"-->
<!--                  :disabled="!editEnable(subTask)"-->
<!--                  :items="priorities"-->
<!--                  :menu-props="{contentClass: 'task-table-select-input-menu', offsetY: true, left: true, minWidth: 210}"-->
<!--                  append-icon=""-->
<!--                  class="task-table-select-input"-->
<!--                  dense-->
<!--                  hide-details-->
<!--                  persistent-hint-->
<!--                  @change="updateTask(subTask)"-->
<!--                >-->
<!--                  <template v-slot:selection="{ item }">-->
<!--                    <UIImage :name-path="`icons/ic-${$config.tasks.priorityLabels[item].toLowerCase()}-priority.svg`"-->
<!--                             height="16"-->
<!--                             width="16"></UIImage>-->
<!--                  </template>-->
<!--                  <template v-slot:item="{ item }">-->
<!--                    <div class="d-flex align-center text_color_19&#45;&#45;text font-weight-medium">-->
<!--                      <UIImage :name-path="`icons/ic-${$config.tasks.priorityLabels[item].toLowerCase()}-priority.svg`"-->
<!--                               class="mr-4" height="14"-->
<!--                               width="14"></UIImage>-->
<!--                      {{ $config.tasks.priorityLabels[item] }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--              </td>-->
<!--              <td>-->
<!--                <v-select-->
<!--                  v-model="subTask.status"-->
<!--                  :disabled="!editEnable(subTask)"-->
<!--                  :items="statuses"-->
<!--                  :menu-props="{contentClass: 'task-table-select-input-menu', offsetY: true, left: true, minWidth: 210}"-->
<!--                  append-icon=""-->
<!--                  class="task-table-select-input"-->
<!--                  dense-->
<!--                  hide-details-->
<!--                  persistent-hint-->
<!--                  @change="updateTask(subTask)"-->
<!--                >-->
<!--                  <template v-slot:selection="{ item }">-->
<!--                    <v-icon :class="`tasks-status-${item}`" size="15">fa-circle</v-icon>-->
<!--                  </template>-->
<!--                  <template v-slot:item="{ item }">-->
<!--                    <div class="d-flex align-center text_color_19&#45;&#45;text font-weight-medium">-->
<!--                      <v-icon :class="`tasks-status-${item} mr-4`" size="14">fa-circle</v-icon>-->
<!--                      {{ $config.tasks.statusLabels[item] }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="d-flex align-center">-->
<!--                  <UISelectAssignee-->
<!--                    v-model="subTask.assignee"-->
<!--                    :assignee-list="getProject.team"-->
<!--                    :disabled="!editEnable(subTask)"-->
<!--                    :menuWidth="260"-->
<!--                    @input="updateTask(subTask)"-->
<!--                  >-->
<!--                    <template v-slot:input>-->
<!--                      <v-tooltip color="accent" top>-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                          <div class="d-flex align-center mr-3" v-bind="attrs" v-on="on">-->
<!--                            <v-avatar size="24">-->
<!--                              <img v-if="subTask.assignee.avatar" :src="subTask.assignee.avatar.url" alt="">-->
<!--                              <v-icon v-else class="text_color_26&#45;&#45;text" size="16">fa-user</v-icon>-->
<!--                            </v-avatar>-->
<!--                          </div>-->
<!--                        </template>-->
<!--                        <span class="white&#45;&#45;text font-weight-medium">{{-->
<!--                            subTask.assignee.first_name-->
<!--                          }} {{ subTask.assignee.last_name }}</span>-->
<!--                      </v-tooltip>-->
<!--                    </template>-->
<!--                  </UISelectAssignee>-->
<!--                  <v-icon class="text_color_26&#45;&#45;text" size="18" @click="showMenu($event, subTask)">fa-ellipsis-v</v-icon>-->
<!--                </div>-->
<!--                <div v-if="subTask.loading"-->
<!--                     class="task-table-tr-loading d-flex align-center justify-center background_19">-->
<!--                  <v-progress-circular :size="32" color="primary" indeterminate/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </v-data-table>-->

<!--        <div v-if="loading" class="d-flex justify-center align-center pa-6">-->
<!--          <v-progress-circular :size="32" color="primary" indeterminate/>-->
<!--        </div>-->

<!--        <v-menu-->
<!--          v-model="taskActionsMenu.menu"-->
<!--          :max-width="160"-->
<!--          :min-width="160"-->
<!--          :position-x="taskActionsMenu.x"-->
<!--          :position-y="taskActionsMenu.y"-->
<!--          allow-overflow-->
<!--          content-class="side-drawer-task-table-action"-->
<!--        >-->
<!--          <v-list class="background_23" light>-->
<!--            <v-list-item @click="openTaskForm">-->
<!--              <v-list-item-title class="font-weight-medium text_color_19&#45;&#45;text">Open</v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item @click="updateTaskFormField(activeTask, 'assignee')">-->
<!--              <v-list-item-title class="font-weight-medium text_color_19&#45;&#45;text">Assign to</v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item disabled style="opacity: .2;">-->
<!--              <v-list-item-title class="font-weight-medium text_color_19&#45;&#45;text">Share</v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item v-if="activeTask && activeTask.parent === null" @click="createSubTask">-->
<!--              <v-list-item-title class="font-weight-medium text_color_19&#45;&#45;text">Create sub task</v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item v-if="editEnable(activeTask)" @click="deleteTask">-->
<!--              <v-list-item-title class="font-weight-medium text_color_19&#45;&#45;text">Delete</v-list-item-title>-->
<!--            </v-list-item>-->
<!--          </v-list>-->
<!--        </v-menu>-->

<!--        <CreateTaskForm-->
<!--          v-if="taskFormData.showForm"-->
<!--          :filters="filters"-->
<!--          :project="getProject"-->
<!--          :task="activeTask"-->
<!--          :taskFormData="taskFormData"-->
<!--          :tasks="tasks"-->
<!--          @closeTaskForm="closeTaskForm"-->
<!--          @createSubTask="createSubTask"-->
<!--          @onChange="tasksChange"-->
<!--        ></CreateTaskForm>-->
<!--      </div>-->
<!--    </v-container>-->
  </div>
</template>

<script>
// import {mapGetters} from "vuex";
// import {format, isSameDay, parseISO} from 'date-fns';
// import {addParamsToLocation, formatDateToIntl} from "@/utils/helpers";
// import UIImage from "../../components/UI/UIImage";
// import UiSearch from "../../components/UI/UiSearch";
// import UIDatePicker from "../../components/UI/UIDatePicker";
// import UISelectAssignee from "../../components/UI/UISelectAssignee";
// import ByDate from "../../views/tasks/ByDate";
// import Filters from "../../views/tasks/Filters";
// import CreateTaskForm from './CreateTaskForm';
import PageHeader from "@/components/layout/PageHeader";

export default {
  name: 'TasksPage',
  components: {
    PageHeader,
    // UiSearch,
    // UIImage,
    // UIDatePicker,
    // UISelectAssignee,
    // ByDate,
    // Filters,
    // CreateTaskForm,
  },
  data() {
    return {
      // loading: true,
      // filters: {
      //   created_at: 'all',
      //   order: 'asc',
      //   search: '',
      // },
      // tasks: {
      //   data: [],
      //   nextLink: null
      // },
      // headers: [
      //   {text: '', value: 'checkbox', sortable: false, width: '30px'},
      //   {text: 'Task name', value: 'name', sortable: true, width: '100%'},
      //   {text: 'Img', value: 'file', sortable: false, width: '48px'},
      //   {text: 'Sub', value: 'sub', sortable: false, width: '48px'},
      //   {text: 'Due date', value: 'due_date', sortable: true, width: '138px'},
      //   {text: 'Priority', value: 'priority', sortable: true, width: '74px'},
      //   {text: 'Status', value: 'status', sortable: true, width: '64px'},
      //   {text: 'Assign', value: 'assignee', sortable: true, width: '64px'},
      // ],
      // statuses: Object.values(this.$config.tasks.status),
      // priorities: Object.values(this.$config.tasks.priority),
      // activeTask: null,
      // taskFormData: {
      //   showForm: false,
      //   assigneeFocus: false,
      //   nameFocus: false,
      //   isPermanent: false,
      // },
      // taskActionsMenu: {
      //   menu: false,
      //   x: 0,
      //   y: 0,
      // },
      // sortModel: {
      //   col: 'name',
      //   order: 'asc',
      // }
    }
  },
  // computed: {
  //   ...mapGetters([
  //     'getAuthId',
  //     'getProjectsList',
  //     'getPermission',
  //     'getProjectPermissionData',
  //   ]),
  //   getProject() {
  //     return this.getProjectsList.find(project => project.id === this.filters.project_id);
  //   },
  // },
  // watch: {
  //   sortModel: {
  //     deep: true,
  //     async handler() {
  //       this.tasks = {data: [], nextLink: null};
  //       await this.loadTasks(`projects/${this.filters.project_id}/tasks`);
  //     }
  //   },
  // },
  // async created() {
  //   const projects = await this.$api.project.list({count: -1});
  //   await this.$store.dispatch('setProjects', projects.data);
  //   if (projects.data.length) {
  //     this.filters.project_id = projects.data[0].id;
  //   }
  //
  //   Object.keys(this.$route.query).forEach(key => {
  //     if (['', 'null', 'undefined'].includes(this.$route.query[key])) return;
  //
  //     switch (key) {
  //       case 'project_id':
  //       case 'project_tag_id':
  //       case 'project_style_id':
  //       case 'status':
  //       case 'priority':
  //       case 'project_module' : {
  //         this.filters[key] = parseInt(this.$route.query[key], 10);
  //         break;
  //       }
  //       case 'assignee': {
  //         this.filters[key] = this.$route.query[key].split(',').filter(item => item !== '');
  //         break;
  //       }
  //       case 'created_at':
  //       case 'order':
  //       case 'search': {
  //         this.filters[key] = this.$route.query[key];
  //         break;
  //       }
  //     }
  //   });
  //   addParamsToLocation(this.$route.path, this.filters);
  //   if (this.filters.project_id) {
  //     await this.loadTasks(`projects/${this.filters.project_id}/tasks`);
  //   } else {
  //     this.loading = false;
  //   }
  // },
  // mounted() {
  //   this.$eventBus.$on('updateFilters', data => {
  //     this.setFilter(data);
  //   })
  // },
  // beforeDestroy() {
  //   this.$eventBus.$off('updateFilters');
  // },
  // methods: {
  //   setFilter(data) {
  //     this.tasks = {
  //       data: [],
  //       nextLink: null
  //     };
  //     this.filters = {...this.filters, ...data};
  //     addParamsToLocation(this.$route.path, this.filters);
  //     this.loadTasks(`projects/${this.filters.project_id}/tasks`);
  //   },
  //   editEnable(task) {
  //     if (task === null) return false;
  //
  //     const {projectOwner, projectManager} = this.$config.project.userRole;
  //     return (task.created_by.id === this.getAuthId ||
  //       this.getProjectPermissionData(this.filters.project_id).roles.some(role => role === projectOwner || role === projectManager)
  //     ) && this.getPermission(this.filters.project_id)['project-s-drawer']['can-edit-task-p-s-d'];
  //   },
  //   taskTemplate() {
  //     return {
  //       id: null,
  //       name: '',
  //       description: '',
  //       priority: this.$config.tasks.priority.critical,
  //       status: this.$config.tasks.status.to_do,
  //       parent: {id: null},
  //       project_tag_id: this.filters.project_tag_id,
  //       project_style_id: this.filters.project_style_id,
  //       project_module_id: this.filters.project_module,
  //       created_at: new Date().setHours(0, 0, 0, 0),
  //       due_date: new Date().setHours(0, 0, 0, 0),
  //       reminder: new Date().setHours(0, 0, 0, 0),
  //       created_by: {id: this.getAuthId},
  //       assignee: this.getProject.team.find(user => user.user.id === this.getAuthId).user,
  //       attachments: [],
  //     }
  //   },
  //   disabledBeforeToday(date) {
  //     const today = new Date();
  //     today.setHours(0, 0, 0, 0);
  //     return date < today;
  //   },
  //   formatDueDate(date) {
  //     const options = {};
  //     let formatDate = '';
  //
  //     if (isSameDay(new Date(), parseISO(date))) {
  //       options.year = undefined;
  //       options.month = undefined;
  //       options.day = undefined;
  //       options.hour = 'numeric';
  //       options.minute = 'numeric';
  //       formatDate += 'Today '
  //     } else {
  //       options.year = 'numeric';
  //       options.month = 'numeric';
  //       options.day = 'numeric';
  //     }
  //
  //     formatDate += formatDateToIntl(date, options);
  //     return formatDate;
  //   },
  //   openTaskForm(task = null) {
  //     if (task && task.id) {
  //       this.activeTask = JSON.parse(JSON.stringify(task));
  //     }
  //     if (this.activeTask === null) {
  //       this.activeTask = this.taskTemplate();
  //     }
  //     this.taskFormData.showForm = true;
  //   },
  //   closeTaskForm() {
  //     this.taskFormData = {
  //       showForm: false,
  //       assigneeFocus: false,
  //       nameFocus: false,
  //       isPermanent: false,
  //     };
  //     this.$nextTick(() => {
  //       this.activeTask = null;
  //     });
  //   },
  //   updateTaskFormField(task, field) {
  //     if (!this.editEnable(task)) return;
  //     this.closeTaskForm();
  //
  //     this.$nextTick(() => {
  //       this.activeTask = JSON.parse(JSON.stringify(task));
  //       this.taskFormData.showForm = true;
  //       this.taskFormData[`${field}Focus`] = true;
  //     });
  //   },
  //   createSubTask() {
  //     if (this.activeTask.parent !== null) return;
  //
  //     this.activeTask = {
  //       ...this.taskTemplate(),
  //       id: null,
  //       parent: {id: this.activeTask.id},
  //       project_tag_id: this.activeTask.project_tag_id,
  //       project_style_id: this.activeTask.project_style_id,
  //     };
  //
  //     this.openTaskForm();
  //   },
  //   showMenu(event, task) {
  //     event.preventDefault();
  //     this.activeTask = JSON.parse(JSON.stringify(task));
  //     this.taskActionsMenu.x = event.clientX
  //     this.taskActionsMenu.y = event.clientY
  //     setTimeout(() => {
  //       this.taskActionsMenu.menu = true;
  //     }, 50)
  //   },
  //   customSort(items, sortBy, sortDesc) {
  //     if (sortBy.length && sortDesc.length) {
  //       this.sortModel.col = sortBy[0];
  //       this.sortModel.order = sortDesc[0] ? 'desc' : 'asc';
  //     }
  //     return items;
  //   },
  //   updateTask(task) {
  //     if (!this.editEnable(task)) return;
  //
  //     task.loading = true;
  //     this.$api.task.update(this.filters.project_id, task.id, {
  //       ...task,
  //       assignee_id: task.assignee.id,
  //       due_date: format(task.due_date, 'yyyy-MM-dd'),
  //       reminder: new Date(task.reminder),
  //       attachments: task.attachments.map(file => file.id),
  //     })
  //       .then(res => {
  //         let data = this.tasks.data.map(item => item.id === res.data.id ? this.formatTaskFromBackend(res.data) : item)
  //
  //         if (res.data.parent !== null) {
  //           data = this.tasks.data.map(task => {
  //             if (task.children.find(subTask => subTask.id === res.data.id)) {
  //               return {
  //                 ...task,
  //                 children: task.children.map(subTask => subTask.id === res.data.id ? this.formatTaskFromBackend(res.data) : subTask),
  //               }
  //             }
  //             return task;
  //           })
  //         }
  //
  //         this.tasks.data = data;
  //
  //         this.$toast.open({
  //           message: 'Task updated successfully',
  //           type: 'success',
  //           position: 'top-right'
  //         });
  //
  //         this.closeTaskForm();
  //       })
  //       .catch(err => {
  //         console.error(err)
  //       });
  //   },
  //   deleteTask() {
  //     this.$store.dispatch('openModal', {
  //       modalName: 'confirmModal',
  //       data: {
  //         title: `Are you sure you want to delete <br> “${this.activeTask.name}“?`,
  //         confirmBtnText: 'Delete'
  //       },
  //       handlers: {
  //         onConfirm: () => {
  //           let data = this.tasks.data.map(task => task.id === this.activeTask.id ? {...task, loading: true} : task);
  //
  //           if (this.activeTask.parent !== null) {
  //             data = this.tasks.data.map(task => {
  //               if (task.children.find(subTask => subTask.id === this.activeTask.id)) {
  //                 return {
  //                   ...task,
  //                   children: task.children.map(subTask => subTask.id === this.activeTask.id ? {
  //                     ...subTask,
  //                     loading: true
  //                   } : subTask),
  //                 }
  //               }
  //               return task;
  //             })
  //           }
  //
  //           this.tasks.data = data;
  //
  //           this.$api.task.delete(this.filters.project_id, this.activeTask.id)
  //             .then(() => {
  //               let data = this.tasks.data.filter(task => task.id !== this.activeTask.id);
  //
  //               if (this.activeTask.parent !== null) {
  //                 data = this.tasks.data.map(task => {
  //                   if (task.children.find(subTask => subTask.id === this.activeTask.id)) {
  //                     return {
  //                       ...task,
  //                       children: task.children.filter(subTask => subTask.id !== this.activeTask.id),
  //                     }
  //                   }
  //                   return task;
  //                 })
  //               }
  //
  //               this.tasks.data = data;
  //
  //               this.$toast.open({
  //                 message: 'Task deleted successfully',
  //                 type: 'success',
  //                 position: 'top-right'
  //               });
  //
  //               this.activeTask = null;
  //             })
  //             .catch(err => {
  //               console.error(err)
  //             })
  //             .finally(() => {
  //               this.$store.dispatch('closeModal', 'confirmModal');
  //             });
  //         },
  //         onCancel: () => {
  //           this.$store.dispatch('closeModal', 'confirmModal');
  //         }
  //       }
  //     });
  //   },
  //   tasksChange(data) {
  //     this.tasks.data = data;
  //   },
  //   async loadTasks(url) {
  //     this.loading = true;
  //     const {project_tag_id, project_style_id, assignee, priority, status, search, project_module} = this.filters;
  //     const {col, order} = this.sortModel;
  //
  //     const tasks = await this.$api.task.list(url, {
  //       project_tag_id,
  //       project_style_id,
  //       assignee,
  //       priority,
  //       status,
  //       search,
  //       col,
  //       order,
  //       project_module: project_module ? [project_module] : null,
  //     });
  //
  //     this.tasks = {
  //       data: [
  //         ...this.tasks.data,
  //         ...tasks.data.map(task => this.formatTaskFromBackend(task)),
  //       ],
  //       nextLink: tasks.links.next,
  //     }
  //
  //     this.loading = false;
  //   },
  //   async loadMoreTasks() {
  //     const tasksBody = this.$refs.tasks_body;
  //     if (!this.tasks.nextLink || this.loading) return;
  //     if (tasksBody.scrollHeight - Math.abs(tasksBody.scrollTop) === tasksBody.clientHeight) {
  //       console.log('Load more project tasks');
  //       await this.loadTasks(this.tasks.nextLink);
  //     }
  //   },
  //   formatTaskFromBackend(task) {
  //     return {
  //       ...task,
  //       due_date: new Date(task.due_date).getTime(),
  //       reminder: new Date(task.reminder).getTime(),
  //       loading: false,
  //       children: task.children.map(child => ({
  //         ...child,
  //         loading: false,
  //         due_date: new Date(child.due_date).getTime(),
  //         reminder: new Date(child.reminder).getTime(),
  //       }))
  //     }
  //   },
  //   go(task) {
  //     this.$router.push({
  //       name: 'DesignViewPage',
  //       params: {
  //         project_id: task.project_id,
  //       },
  //       query: {
  //         module_id: task.project_module_id,
  //         style_id: task.project_style_id,
  //         file_id: task.project_item.id,
  //         task_id: task.id,
  //         view_mode: this.$config.project.designModes.task,
  //       },
  //     })
  //   }
  // }
}
</script>

<!--<style lang="scss">-->
<!--.tasks-page {-->
<!--  .common-page-filters {-->
<!--    width: 100%;-->

<!--    .side-drawer-filters-menu {-->
<!--      min-width: calc(100% - 72px) !important;-->
<!--      max-width: calc(100% - 72px) !important;-->
<!--      width: calc(100% - 72px) !important;-->
<!--    }-->
<!--  }-->

<!--  .project-side-drawer-tasks {-->
<!--    position: relative;-->
<!--  }-->

<!--  .tasks-table {-->
<!--    &::before {-->
<!--      content: '';-->
<!--      position: absolute;-->
<!--      top: 0;-->
<!--      left: 0;-->
<!--      right: 0;-->
<!--      height: 52px;-->
<!--      background-color: inherit;-->
<!--    }-->

<!--    max-width: 980px;-->
<!--    width: 100%;-->
<!--    margin: 0 auto;-->

<!--    .v-data-table-header tr th {-->
<!--      height: 52px;-->
<!--      &:nth-child(2) {-->
<!--        min-width: 250px!important;-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  .breadcrumbs {-->
<!--    & > div {-->
<!--      max-width: 33.3%;-->
<!--    }-->
<!--  }-->

<!--  .create-task-form {-->
<!--    top: 0;-->
<!--  }-->
<!--}-->
<!--</style>-->
